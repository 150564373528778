.gray-pencil {
  background: url(../images/icons/gray-pencil.png) no-repeat;
  width: 20px;
  height: 20px;
  background-size: 100%;
}
dialog.gitb-register-modal {
  padding: 0;
}
dialog.avatar-upload .upload-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
dialog.avatar-upload .upload-message > div.hide-section {
  max-width: 400px;
}
.gitb-register-modal,
.styled-modal {
  line-height: 1.2;
  border: none;
  background-color: white;
  text-align: center;
  position: fixed;
  top: 0px;
  left: 0;
  transition: transform 0.3s ease-out;
  overflow: hidden;
  z-index: 3000;
  margin: 15vh auto;
  width: 60vw;
  font-family: 'Lato', sans-serif;
}
@media (max-width: 1616px) {
  .gitb-register-modal,
  .styled-modal {
    width: 80vw;
  }
}
@media (max-height: 780px) {
  .gitb-register-modal,
  .styled-modal {
    margin: 5vh auto;
  }
}
@media (min-width: 813px) {
  .gitb-register-modal,
  .styled-modal {
    box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.3);
  }
}
@media (max-width: 812px) {
  .gitb-register-modal,
  .styled-modal {
    width: 100vw;
    height: 100%;
    margin: 0 auto;
  }
}
.gitb-register-modal.gitb-modal-narrow,
.styled-modal.gitb-modal-narrow {
  width: clamp(340px, 30vw, 80vw);
}
.gitb-register-modal.open-dialog,
.styled-modal.open-dialog {
  transform: none;
}
.gitb-register-modal header,
.styled-modal header {
  margin: 10px auto;
}
.gitb-register-modal .close-modal-button,
.styled-modal .close-modal-button {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.85;
  color: #848484;
  z-index: 1;
}
.gitb-register-modal .close-modal-button:hover,
.styled-modal .close-modal-button:hover {
  opacity: 1;
}
.gitb-register-modal .modal-input-field,
.styled-modal .modal-input-field {
  margin-bottom: 3px;
  margin-left: auto;
  margin-right: auto;
  padding: 5px 10px;
  border-radius: 5px;
  border: solid 1px lightgrey;
  text-align: left;
  width: 67%;
  max-width: 300px;
}
.gitb-register-modal .modal-input-field input.modal-input,
.styled-modal .modal-input-field input.modal-input {
  background-color: white;
  border-radius: 2px;
  padding-left: 10px;
  height: 40px;
  display: inline-block;
  border: none;
  outline: none;
  color: #4D4D4D;
}
.gitb-register-modal .modal-input-field ul.parsley-errors-list li,
.styled-modal .modal-input-field ul.parsley-errors-list li {
  font-weight: bold;
  background: none;
  color: red;
}
@media (max-width: 1367px) {
  .gitb-register-modal .modal-input-field,
  .styled-modal .modal-input-field {
    margin-bottom: 30px !important;
  }
  .gitb-register-modal .modal-actions,
  .styled-modal .modal-actions {
    display: flex;
    justify-content: center;
  }
  .gitb-register-modal .modal-disclaimer,
  .styled-modal .modal-disclaimer,
  .gitb-register-modal .modal-links,
  .styled-modal .modal-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .gitb-register-modal ul.parsley-errors-list,
  .styled-modal ul.parsley-errors-list {
    top: 110% !important;
  }
}
@media (max-width: 1367px) {
  .gitb-register-modal .btn:not(.btn-block),
  .styled-modal .btn:not(.btn-block),
  .gitb-register-modal span:not(.btn-block),
  .styled-modal span:not(.btn-block) {
    width: 350px !important;
  }
}
@media (max-width: 615px) {
  .gitb-register-modal .btn:not(.btn-block),
  .styled-modal .btn:not(.btn-block),
  .gitb-register-modal span:not(.btn-block),
  .styled-modal span:not(.btn-block) {
    width: 100% !important;
  }
}
.gitb-register-modal#phone-verification-modal,
.styled-modal#phone-verification-modal {
  padding: 0;
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
}
.gitb-register-modal#phone-verification-modal .modal-input-field:has(.iti),
.styled-modal#phone-verification-modal .modal-input-field:has(.iti) {
  padding: 5px 0;
  width: 100%;
  max-width: 100%;
}
.gitb-register-modal#phone-verification-modal .iti,
.styled-modal#phone-verification-modal .iti {
  display: block;
  width: 100%;
}
.gitb-register-modal#phone-verification-modal .iti .iti__dropdown-content,
.styled-modal#phone-verification-modal .iti .iti__dropdown-content {
  margin-top: 15px;
}
.gitb-register-modal#phone-verification-modal .iti .iti__dropdown-content .iti__search-input,
.styled-modal#phone-verification-modal .iti .iti__dropdown-content .iti__search-input {
  padding: 0.5rem;
}
.gitb-register-modal#phone-verification-modal .iti .iti__dropdown-content .iti__search-input:focus-visible,
.styled-modal#phone-verification-modal .iti .iti__dropdown-content .iti__search-input:focus-visible {
  outline: none;
}
.gitb-register-modal#phone-verification-modal .intl-tel-input,
.styled-modal#phone-verification-modal .intl-tel-input {
  padding-left: 52px;
}
@media (min-width: 813px) {
  .gitb-register-modal#phone-verification-modal,
  .styled-modal#phone-verification-modal {
    width: clamp(30vw, 540px, 100%);
    height: clamp(570px, 62vh, 100%);
    overflow-y: auto;
  }
}
@media (max-width: 812px) {
  .gitb-register-modal#phone-verification-modal,
  .styled-modal#phone-verification-modal {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
  }
  .gitb-register-modal#phone-verification-modal .phone-legend-span,
  .styled-modal#phone-verification-modal .phone-legend-span {
    display: block;
  }
  .gitb-register-modal#phone-verification-modal .period,
  .styled-modal#phone-verification-modal .period {
    display: none;
  }
  .gitb-register-modal#phone-verification-modal #edit-phone-screen .g-btn,
  .styled-modal#phone-verification-modal #edit-phone-screen .g-btn {
    width: 100%;
    margin-top: 20px;
  }
}
.gitb-register-modal#phone-verification-modal input::-webkit-outer-spin-button,
.styled-modal#phone-verification-modal input::-webkit-outer-spin-button,
.gitb-register-modal#phone-verification-modal input::-webkit-inner-spin-button,
.styled-modal#phone-verification-modal input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.gitb-register-modal#phone-verification-modal input[type=number],
.styled-modal#phone-verification-modal input[type=number] {
  -moz-appearance: textfield;
}
.gitb-register-modal#phone-verification-modal .code-field,
.styled-modal#phone-verification-modal .code-field {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: fit-content;
  position: relative;
}
.gitb-register-modal#phone-verification-modal .code-field:before,
.styled-modal#phone-verification-modal .code-field:before {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  position: absolute;
}
@media (min-width: 813px) {
  .gitb-register-modal#phone-verification-modal .code-field:before,
  .styled-modal#phone-verification-modal .code-field:before {
    padding-top: 15px;
    left: -50px;
    font-size: 30px;
  }
}
@media (max-width: 812px) {
  .gitb-register-modal#phone-verification-modal .code-field:before,
  .styled-modal#phone-verification-modal .code-field:before {
    padding-top: 10px;
    left: -30px;
    font-size: 25px;
  }
}
@media (max-width: 437px) {
  .gitb-register-modal#phone-verification-modal .code-field:before,
  .styled-modal#phone-verification-modal .code-field:before {
    padding-top: 12px;
    left: -20px;
    font-size: 18px;
  }
}
.gitb-register-modal#phone-verification-modal .code-field.valid:before,
.styled-modal#phone-verification-modal .code-field.valid:before {
  content: '\f058';
  color: #70CACA;
}
.gitb-register-modal#phone-verification-modal .code-field.invalid:before,
.styled-modal#phone-verification-modal .code-field.invalid:before {
  content: '\f057';
  color: #bc0505;
}
.gitb-register-modal#phone-verification-modal .code-input,
.styled-modal#phone-verification-modal .code-input {
  box-sizing: border-box;
  width: clamp(35px, 6vw, 52px);
  height: clamp(35px, 6vw, 52px);
  text-align: center;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
@media (min-width: 813px) {
  .gitb-register-modal#phone-verification-modal .code-input,
  .styled-modal#phone-verification-modal .code-input {
    font-size: 40px;
  }
}
@media (max-width: 812px) {
  .gitb-register-modal#phone-verification-modal .code-input,
  .styled-modal#phone-verification-modal .code-input {
    font-size: 25px;
  }
}
.gitb-register-modal#phone-verification-modal #verify-code-screen,
.styled-modal#phone-verification-modal #verify-code-screen {
  max-width: 540px;
}
.gitb-register-modal#phone-verification-modal #verify-code-screen,
.styled-modal#phone-verification-modal #verify-code-screen,
.gitb-register-modal#phone-verification-modal #edit-phone-screen,
.styled-modal#phone-verification-modal #edit-phone-screen,
.gitb-register-modal#phone-verification-modal #custom-screen,
.styled-modal#phone-verification-modal #custom-screen {
  max-width: 400px;
}
.gitb-register-modal#phone-verification-modal .intl-tel-input,
.styled-modal#phone-verification-modal .intl-tel-input {
  font-size: clamp(15px, 6vw, 30px);
}
.gitb-register-modal#phone-verification-modal .error-message,
.styled-modal#phone-verification-modal .error-message {
  background-color: rgba(188, 5, 5, 0.25);
  color: #bc0505;
  border: 1px solid #bc0505;
  border-radius: 3px;
  font-weight: bold;
  padding: 1.5em;
  font-size: 12px;
}
.gitb-register-modal#report-confirmation-modal,
.styled-modal#report-confirmation-modal {
  padding: 0;
  width: clamp(30vw, 540px, 100%);
}
@media (max-width: 812px) {
  .gitb-register-modal#report-confirmation-modal,
  .styled-modal#report-confirmation-modal {
    height: 100vh;
    padding-top: 40px;
    transform: none;
  }
}
.gitb-register-modal .modal-header,
.styled-modal .modal-header {
  display: block;
  padding: 0;
  border: 0;
  font-weight: 900;
  font-family: 'Martel', serif;
  margin: 2rem 0 1rem;
  font-size: 25px;
}
.gitb-register-modal .modal-body,
.styled-modal .modal-body {
  padding: 1rem;
}
.gitb-register-modal .modal-content,
.styled-modal .modal-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  color: #4D4D4D;
  place-content: center;
}
@media (max-width: 1300px) {
  .gitb-register-modal .modal-content,
  .styled-modal .modal-content {
    grid-template-columns: 100%;
  }
}
.gitb-register-modal .modal-content > *,
.styled-modal .modal-content > * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.gitb-register-modal .modal-register-left,
.styled-modal .modal-register-left {
  padding: 50px 30px;
  background-color: #E5E6E1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 15px;
}
@media (max-width: 1300px) {
  .gitb-register-modal .modal-register-left,
  .styled-modal .modal-register-left {
    display: none;
  }
}
.gitb-register-modal .modal-register-left .benefit-items,
.styled-modal .modal-register-left .benefit-items {
  display: flex;
  flex-direction: column;
  margin: 50px 0 90px 0;
  justify-content: center;
  align-items: flex-start;
}
.gitb-register-modal .modal-register-left .benefit-items .item,
.styled-modal .modal-register-left .benefit-items .item {
  padding: 5px 0;
}
.gitb-register-modal .modal-register-left .benefit-items .item:before,
.styled-modal .modal-register-left .benefit-items .item:before {
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  content: "\f00c";
  color: #FFCC00;
  padding-right: 8px;
}
.gitb-register-modal .modal-register-left .featured-in > span,
.styled-modal .modal-register-left .featured-in > span {
  display: inline-block;
  margin: 10px;
  filter: invert(45%) sepia(8%) saturate(0%) hue-rotate(143deg) brightness(102%) contrast(97%);
}
.gitb-register-modal .modal-register-right,
.styled-modal .modal-register-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 10%;
}
.gitb-register-modal .modal-links,
.styled-modal .modal-links {
  margin-bottom: 10px;
}
@media (min-width: 1367px) {
  .gitb-register-modal .modal-links span,
  .styled-modal .modal-links span {
    width: 100%;
  }
}
.gitb-register-modal #modal-linkedin-button,
.styled-modal #modal-linkedin-button {
  margin-bottom: 20px;
  outline: 0;
}
.gitb-register-modal .modal-signin-text,
.styled-modal .modal-signin-text {
  font-size: 15px;
}
.gitb-register-modal .modal-disclaimer,
.styled-modal .modal-disclaimer {
  font-size: 12px;
  text-align: left;
  line-height: 1.5;
}
@media (max-width: 1300px) {
  .gitb-register-modal .modal-disclaimer,
  .styled-modal .modal-disclaimer {
    margin-bottom: 25px;
  }
}
@media (max-width: 1300px) {
  .gitb-register-modal .linkedin-button-line,
  .styled-modal .linkedin-button-line {
    margin-bottom: 25px;
  }
}
.gitb-register-modal .modal-actions,
.styled-modal .modal-actions {
  margin: 15px 0 30px;
}
.gitb-register-modal .modal-styled-header,
.styled-modal .modal-styled-header {
  padding: 15px;
  width: 100%;
  border-bottom: 1px solid #D7D7D7;
  margin-bottom: 25px;
  color: #4D4D4D;
  font-size: 22px;
  text-align: left;
}
.gitb-register-modal .modal-input-field,
.styled-modal .modal-input-field {
  margin: 5px 0 0px 0;
  width: 100%;
  max-width: 100%;
  padding: 10px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
}
.gitb-register-modal .modal-input-field input.modal-input,
.styled-modal .modal-input-field input.modal-input {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 10px;
  height: auto;
  width: 100%;
}
.gitb-register-modal .modal-input-field input.modal-input:focus,
.styled-modal .modal-input-field input.modal-input:focus {
  border-color: #36627F;
}
.gitb-register-modal .modal-input-field ul.parsley-errors-list li,
.styled-modal .modal-input-field ul.parsley-errors-list li {
  font-weight: normal;
  color: #bc0505;
  font-size: 12px;
  margin: 3px 10px 0 0;
}
.gitb-register-modal .modal-input-field .parsley-errors-list,
.styled-modal .modal-input-field .parsley-errors-list {
  position: absolute;
}
@media (min-width: 813px) {
  .gitb-register-modal .modal-input-field .parsley-errors-list,
  .styled-modal .modal-input-field .parsley-errors-list {
    top: -25px;
    right: 0;
  }
}
@media (max-width: 812px) {
  .gitb-register-modal .modal-input-field .parsley-errors-list,
  .styled-modal .modal-input-field .parsley-errors-list {
    top: 110%;
    left: 0;
    margin-left: 0;
    padding-left: 0;
  }
}
#verification-phone-form ul.parsley-errors-list li {
  color: #bc0505;
}
#verification-phone-form .parsley-errors-list {
  left: 0;
  top: 100%;
  margin-top: 3px;
}
.styled-modal.open-dialog {
  padding: 0px;
}
.styled-modal footer {
  margin-top: 25px;
  border-top: 1px solid #D7D7D7;
  padding: 15px;
  text-align: center;
}
.styled-modal footer a:hover {
  opacity: 0.8;
}
.fp-press-in-modal {
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#join-itcs1 .description {
  font-size: 85%;
  width: clamp(98vw, 304px);
  margin: 8px 0 25px;
}
.gdpr-consent {
  appearance: none;
  border: 1px solid #A9A9A9;
  border-radius: 3px;
  width: 14px;
  height: 14px;
  padding: 0 !important;
  margin: 4px 5px 0 0;
}
.gdpr-consent:checked {
  background-color: #FFD700;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  border: 1px solid #FFD700;
}
.gdpr-consent + label a {
  text-decoration-color: #08090D !important;
  text-underline-offset: unset !important;
  text-decoration-thickness: 1px !important;
}
.captcha-line {
  display: flex;
  justify-content: center;
}
.captcha-line .g-recaptcha {
  margin: 20px 0;
}
#modal-linkedin-button,
.modal-button {
  display: block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  color: #FFFFFF;
  text-decoration: none;
  text-align: center;
  font-size: 15px;
  padding: 0.8em 3em;
  border-radius: 5px;
  border-style: none;
  font-weight: 600;
}
#modal-linkedin-button:visited,
.modal-button:visited,
#modal-linkedin-button:link,
.modal-button:link {
  color: #FFFFFF;
}
#modal-linkedin-button:hover,
.modal-button:hover {
  text-decoration: none !important;
}
@media (max-width: 1300px) {
  #modal-linkedin-button i,
  .modal-button i {
    display: none;
  }
}
#g-modal-backdrop {
  width: 100vw;
  height: 100vh;
  z-index: 2999;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #08090D;
  opacity: 0.5;
  display: none;
}
[class^="logo-sm-grey"] {
  background: url(../images/logos_external/grey_small_sprite.png) no-repeat;
  display: block;
}
.logo-sm-grey-wsj-acro {
  height: 18px;
  width: 33px;
  background-position: -68px -91px;
}
.logo-sm-grey-forbes {
  height: 18px;
  width: 69px;
  background-position: -5px -5px;
}
.logo-sm-grey-forrester {
  height: 20px;
  width: 125px;
  background-position: -5px -33px;
}
.logo-sm-grey-IDC {
  height: 18px;
  width: 74px;
  background-position: -84px -5px;
}
.logo-sm-grey-infoweek {
  height: 18px;
  width: 153px;
  background-position: -5px -63px;
}
.logo-sm-grey-USA-today {
  height: 20px;
  width: 53px;
  background-position: -5px -91px;
}
@media (max-width: 812px) {
  .modal-itcs-logo {
    background: url(../images/logos/ITCS_logo_trans_no_tagline_154.png) no-repeat 0 0;
    width: 155px;
    height: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .modal-top-text {
    font-size: 17px;
  }
  .gitb-register-modal .btn:not(.btn-block),
  .styled-modal .btn:not(.btn-block) {
    width: 220px;
  }
  footer {
    display: none;
  }
  .modal-signin-text {
    margin-top: 10px;
  }
}
@media (min-width: 813px) {
  .modal-top-text {
    font-size: 22px;
    margin: 0.7rem 1rem;
    font-weight: 600;
  }
  footer {
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
  }
  footer .centertext {
    text-align: center;
  }
  footer .centering {
    height: 80px;
    display: flex;
    justify-content: center;
  }
  footer .centering ul {
    width: 350px;
  }
  footer li.register-adv-item {
    margin-bottom: 6px;
    color: #36627F;
    padding-left: 0;
    list-style-type: disc;
  }
  footer li.register-adv-item span {
    color: #08090D;
  }
}
