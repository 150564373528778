#modal-cta {
  display: none;
  position: fixed;
  width: 270px;
  height: 100px;
  padding: 20px;
  text-align: left;
  bottom: 15px;
  right: 15px;
  background: #08090D;
  color: white;
  border-radius: 10px;
  z-index: 10000;
  box-sizing: content-box;
  font-family: 'Lato', sans-serif;
}
#modal-cta.larger {
  width: 420px;
  height: 134px;
}
@media (max-width: 812px) {
  #modal-cta.larger {
    width: 90%;
    padding: 5%;
  }
}
#modal-cta .bg-cta {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#modal-cta .bg-cta img {
  border-radius: 10px;
}
@media (max-width: 812px) {
  #modal-cta {
    right: 0;
    bottom: 0;
    width: 90%;
    padding: 5%;
    border-radius: 0px;
    height: 50px;
  }
}
#modal-cta .close-cta {
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
  z-index: 20000;
}
#modal-cta .header,
#modal-cta .top-header {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  z-index: 20000;
}
@media (max-width: 812px) {
  #modal-cta .header,
  #modal-cta .top-header {
    font-size: 17px;
  }
}
#modal-cta .top-header {
  border-bottom: 1px solid #666560;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  width: 100%;
  justify-content: flex-start;
}
#modal-cta .top-header > * {
  align-self: center;
}
@media (max-width: 812px) {
  #modal-cta .top-header {
    font-size: 15px;
  }
}
#modal-cta .top-header .cta-text {
  margin-left: 20px;
}
#modal-cta .top-header .cta-text .hdr {
  font-size: 30px;
  font-weight: 700;
  line-height: 28px;
}
@media (max-width: 812px) {
  #modal-cta .top-header .cta-text .hdr {
    font-size: 20px;
  }
}
#modal-cta .content {
  position: relative;
  z-index: 20000;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
#modal-cta .content > * {
  align-self: center;
}
#modal-cta .content:last-child {
  margin-left: auto;
}
#modal-cta .cta {
  position: relative;
  z-index: 20000;
}
@media (max-width: 812px) {
  #modal-cta .cta {
    position: absolute;
    right: 40px;
    top: 9px;
  }
}
