@import '../theme/default_styles';

body {
  &.pages2.home {
    .gitb-page {
      @media(min-width: $desktop-start) {
        //background: url('https://itcentralstation-res.cloudinary.com/image/upload/q_auto,f_auto/v1640006851/home-hero.png') no-repeat center top;
        background: url(https://images.peerspot.com/image/upload/q_auto,f_auto/v1654171079/home-page-hero-background_1.jpg) no-repeat center top;
      }
      @media(max-width: $mobile-end) {
        //background: url('https://itcentralstation-res.cloudinary.com/image/upload/q_auto,f_auto/v1640167586/home-hero-mobile.png') no-repeat left top;
      }
    }
  }
}

#hero {
  .home-hero {
    @media(min-width: $desktop-start) {
      text-align: center;
      h1 {
        margin: 0 auto;
        padding-top: 95px;
        font-size: clamp(40px, 4vw, 57px);
      }
    }

    @media(max-width: $mobile-end) {
      h1 {
        padding-top: 50px;
      }
    }
    .search-wrapper {
      @media(min-width: $desktop-start) {
        max-width: 800px;
        margin: 0 auto;
      }
    }
    .home-search {
      position: relative;
      @media(min-width: $desktop-start) {
        height: 72px;
        padding: 15px 30px;
        width: 100%; // remove if we put back the animation
        //width: 70px;
        //transition: width 2s;
        //transition-delay: 0.7s;
        input {
          font-size: 30px;
        }
        &:before {
          @include branding-flag-bottom(40px, $primary);
        }
      }
      @media(max-width: $mobile-end) {
        height: 56px;
        padding: 10px 15px;
        width: 100%;
        input {
          font-size: 18px;
        }
        &:before {
          @include branding-flag-bottom(32px, $primary);
        }
      }
      box-sizing: border-box;
      background: $primary;

      form {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      input {
        color: $black;
        font-family: $font-family;
        font-weight: 700;
        box-sizing: border-box;
        border: 0;
        background: transparent;
        //width: 0; // removing typewriter animation
        width: 90%;
        //@include branding-caret($white);
        &::placeholder {
          color: $white;
          font-weight: 600;
          opacity: 0.5;
        }
        &:focus::placeholder {
          color: $white;
        }
        &:focus-visible {
          outline: none;
        }
        //@include branding-cursor-blinking(10px, white);
        //&.transition-trigger {
        //  @include typewriter;
        //}
      }
      .search-close { display: none; }
      .search-submit {
        cursor: pointer;
        position: relative;
        width: 10%;
        text-align: center;
        color: $white;
        .submit-form {
          @media(max-width: $mobile-end) {
            font-size: 20px;
          }
          @media(min-width: $desktop-start) {
            position: absolute;
            //top: -90px;
            top: -15px; // remove if we put back the animation
            transition: top 0.8s ease-in;
            transition-delay: 2s;
            font-size: 28px;
            overflow: hidden;
            //&.transition-trigger {
            //  top: -15px;
            //}
          }
          color: $white;
        }
      }
      //&.transition-trigger { width: 100%; }
      .autocomplete-suggestions {
        border-top: 2px solid #FFFFFF40;
        background: $primary;
        overflow: auto;
        top: 100%;
        left: 0;
        width: 100% !important;
        box-sizing: border-box;
        text-align: left;

        &::-webkit-scrollbar {
          max-width: 5px;
        }

        /* Handle */

        &::-webkit-scrollbar-thumb {
          background-color: darken($primary, 3%);
          border-radius: 10%;
        }

        /* Track */

        &::-webkit-scrollbar-track {
          background-color: lighten($primary, 20%);
        }

        .autocomplete-suggestion {
          padding: 8px 30px;
          font-size: 18px;
          cursor: pointer;
          line-height: 1.5;
          color: $black;
          font-family: $font-family-secondary;
          font-weight: 400;
          strong {
            font-weight: 600;
            color: $black;
          }
          &:last-of-type {
            margin-bottom: 30px;
          }
        }

        .autocomplete-selected {
          background: #FFFFFF25;
        }

        .autocomplete-group {
          padding: 30px 0 10px 0;
          margin: 0 30px 10px 30px;
          color: $black;
          opacity: 0.7;
          text-transform: uppercase;
          font-size: 14px;
          border-bottom: 1px solid #FFFFFF40;
          strong {
            display: block;
            border-bottom: 2px solid #FFFFFF40;
          }
          &:first-of-type {
            padding-top: 10px;
            margin-top: 30px;
          }
        }
      }
    }
  }
}

#feature-block {
  @media(max-width: map-get($grid-breakpoints, "lg")) and (min-width: map-get($grid-breakpoints, "md")) {
    img.info-graphic {
      width: 95px;
      height: auto;
    }
    h4 { font-size: 17px; }
    .subheader-sm { font-size: 14px; }
  }
  .feature {
    &:nth-of-type(2) {
      // hack for the second info graphic which is different dimensions than the rest :(
      @media(min-width: map-get($grid-breakpoints, "lg")) {
        margin-top: 1.5rem;
      }
      @media(max-width: map-get($grid-breakpoints, "lg")) and (min-width: map-get($grid-breakpoints, "md")) {
        margin-top: 0.4rem;
      }
    }
    @media (max-width: $mobile-end) {
      &:not(:first-of-type) {
        padding-top: 65px;
      }
    }
  }
}

#categories {
  .product-col {
    //Hide the last odd element if the items are displayed in 2 columns
    @media(max-width: map-get($grid-breakpoints, "lg")) {
      &:last-child:nth-child(odd) {
        display: none;
      }
    }
  }
  #category-nav-content {
    @media(max-width: map-get($grid-breakpoints, "lg")) {
      margin: 0 1.5rem;
    }
  }
}

#featured {
  .press-layout {
    flex-wrap: nowrap;
    .col {
      img { width: auto; height: clamp(20px,3vw,37px); }
    }
  }
  @media(max-width: map-get($grid-breakpoints, "md")) {
    display: none;
  }
}

#quick-links {
  background: $gray-200;
  .header {
    color: $secondary;
    font-size: 20px;
    font-weight: 900;
    padding-bottom: 25px;
  }
  @include list-links;
}

